import React from "react"

import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"

import { Loader } from "~/components/ui"
import useSiteMetadata from "~/hooks/useSiteMetadata"
import { apiCommunityPostToPost } from "~/models/CommunityPost"
import pathFromFullId from "~/routes/pathFromFullId"
import { useGetPostByIdQuery } from "~/state/api/community"
import withTemplate, {
  TemplateDefaultPropTypes,
} from "~/templates/withTemplate"

type PostRedirectionPropsType = TemplateDefaultPropTypes & { postId: string }

const PostRedirection = ({ postId }: PostRedirectionPropsType) => {
  const intl = useIntl()
  const { siteUrl } = useSiteMetadata()

  const {
    data: apiPost,
    isError,
    isSuccess,
  } = useGetPostByIdQuery({ uuid: postId }, { skip: !postId })

  const post = apiPost?.post ? apiCommunityPostToPost(apiPost?.post) : undefined

  return isError ? (
    <Helmet>
      <meta httpEquiv="refresh" content={`0; url=${siteUrl}/${intl.locale}/`} />
    </Helmet>
  ) : isSuccess && intl && post ? (
    <Helmet>
      <meta
        httpEquiv="refresh"
        content={`0; url=${siteUrl}${pathFromFullId(
          `locale/recipes/recipe`,
          intl,
          post?.recipe
        )}?postToDisplay=${post?.id}`}
      />
    </Helmet>
  ) : (
    <Loader />
  )
}

export default withTemplate(PostRedirection)
